.gm-style .gm-style-iw {
  font-weight: 300;
  font-size: 13px;
  overflow: hidden;
  top: auto !important;
  position: absolute;
  left: auto !important;
  bottom: auto !important;
}

.gm-style-iw.gm-style-iw-c {
  padding: 0 !important;
  padding-top: 0px;
}

.gm-style-iw-d {
  padding: 0 !important;
  overflow: auto !important;
}
.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw-ch {
  padding-top: 0px;
}
