.map-label {
  position: relative;
  bottom: 70px;
  width: 120px;
  height: 100px;
  white-space: normal;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 88%;
  flex-direction: column;
  word-wrap: break-word;
  overflow: hidden;
  text-shadow: rgb(255, 255, 255) 1px 0px 0px, rgb(255, 255, 255) 0.540302px 0.841471px 0px,
    rgb(255, 255, 255) -0.416147px 0.909297px 0px, rgb(255, 255, 255) -0.989993px 0.14112px 0px,
    rgb(255, 255, 255) -0.653644px -0.756803px 0px, rgb(255, 255, 255) 0.283662px -0.958924px 0px,
    rgb(255, 255, 255) 0.96017px -0.279416px 0px;
}

.cluster-label {
  position: relative;
  bottom: 5px;
  left: -0.5px;
  text-align: center;
  width: 30px;
}

.route-label {
  white-space: pre-line;
  position: relative;
  left: -0.5px;
  margin-bottom: 28px;
  line-height: 1.2rem;
  text-shadow: rgb(255, 255, 255) 1px 0px 0px, rgb(255, 255, 255) 0.540302px 0.841471px 0px,
    rgb(255, 255, 255) -0.416147px 0.909297px 0px, rgb(255, 255, 255) -0.989993px 0.14112px 0px,
    rgb(255, 255, 255) -0.653644px -0.756803px 0px, rgb(255, 255, 255) 0.283662px -0.958924px 0px,
    rgb(255, 255, 255) 0.96017px -0.279416px 0px;
}
